import React, { useState, useEffect } from "react";
import Moment from "moment";

import { Empresa, Campo, Cuartel, Visita } from "../models";
import { Box, Button, Typography } from "@mui/material";

interface VisitaCardProps {
  visita: Visita;
  dateFormat: string | null;
}

export default function VisitaCard(props: VisitaCardProps) {
  const { visita, dateFormat } = props;
  const [campoName, setCampoName] = useState("");

  return (

    <Box
      style={{
        padding: 12,
        borderRadius: 6,
        margin: 4,
        width: '240px'
      }}
    >
      <Typography style={{ fontSize: 12 }}>{Moment(visita.createdAt).format(dateFormat || 'HH:mm')}</Typography>
      <Typography style={{ fontWeight: "bold" }}>{campoName}</Typography>
      <Typography style={{ fontWeight: "bold" }}>{visita.content?.body}</Typography>
      <Typography style={{ fontWeight: "200" }}>{(visita?.content?.body?.length ?? 0) > 30 ? visita?.content?.body?.substring(0, 30) + "..." : visita?.content?.body}</Typography>
      <Typography style={{ fontWeight: "bold" }}>{visita.operator}</Typography>
    </Box>
  );
}
