

import { Amplify } from 'aws-amplify';
import amplifyconfig from './aws-exports';

import { I18n } from '@aws-amplify/core';
import { Authenticator, Button } from '@aws-amplify/ui-react';
import { translations } from '@aws-amplify/ui-react';
import {
  Outlet,
  RouterProvider,
} from "react-router-dom";
import router from './containers/router';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

Amplify.configure(amplifyconfig);

I18n.putVocabularies(translations);
I18n.setLanguage("es");

const theme = createTheme({
  palette: {
    primary: {
      main: "#a94830",
    },
    secondary: {
      main: "#09425f",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
