import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import { type Visita } from "../API";
import { getUrl } from 'aws-amplify/storage';
import { generateClient } from 'aws-amplify/api';
import { Box, Container, Grid } from "@mui/material";

import { getVisita } from '../graphql/queries';
import { Title, TitleOutlined } from "@mui/icons-material";
import Moment from 'moment';

const client = generateClient();

interface VisitaViewProps {
  vId: string
}

const VisitaView = ({vId }: VisitaViewProps) => {
  const urlVisitaId = useParams()
  //Recuperamos el id de las propriedades del component o desde la URL
  const targetVisitaId = vId || urlVisitaId.vId;

  const [visita, setVisita] = useState<Visita | null>(null);
  const [imageURLs, setImageUrls] = useState<Map<string, string>>(new Map<string, string>());
  const [pictureHeight, setHeight] = useState(360);

  useEffect(() => {
    fetchVista(targetVisitaId || "");
  }, [targetVisitaId]);

  const fetchVista = async (vid: string) => {
    try {
      const visitaData = await client.graphql({
        query: getVisita,
        variables: {
          id: vid
        }
      });
      const visita = visitaData.data.getVisita || null;

      //Recuperamos para cada foto, su URL S3 para mostrarlas
      let urlByKey: Map<string, string> = new Map<string, string>();
      visita?.content?.pictures?.forEach((key => {
        if (key != null) {
          getUrl({ key: key }).then((storeUri) => urlByKey.set(key, storeUri.url.toString())).catch((ex) => console.log("getUrlErr", ex));
        }
      }));

      setImageUrls(urlByKey);
      setVisita(visita);
    } catch (err) {
      console.log("fetchVista", err);
    }
  }

  return (
    <Box p={2}>
      <h2>Reporte de visita</h2>
      <p>Logo de la empresa</p>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <h3>Operador</h3>
          <p>{visita?.operator}</p>
        </Grid>

        <Grid item xs={12} sm={4}>
          <h3>Etiquetas de visita</h3>
          <p>{visita?.tags?.join(" ")}</p>
        </Grid>

        <Grid item xs={12} sm={4}>
          <h3>Fecha</h3>
          <p>{Moment(visita?.fecha).format("DD-MM-YYYY HH:mm")}</p>
        </Grid>

        <Grid item xs={12} sm={4}>
          <h3>Nombre del campo</h3>
          <p>{visita?.campo?.name}</p>
        </Grid>

        <Grid item xs={12} sm={4}>
          <p></p>
        </Grid>

        <Grid item xs={12} sm={4}>
          <h3>Ubicación</h3>
          <p>{visita?.campo?.location}</p>
        </Grid>

        <Grid item xs={12} sm={12}>
          <h3>Nota</h3>
          <p>{visita?.content?.body}</p>
        </Grid>

        <Grid item xs={12} sm={12}>
          <h3>Imagenes</h3>
          <Grid container>
            {
              Array.from(imageURLs, ([key, url]) => ({ key, url })).map((elem) =>
                <Grid item sm={3}>
                  <img src={elem.url} key={elem.key} height={pictureHeight} onClick={() => {
                    if (pictureHeight === 360) {
                      setHeight(720);
                    }
                    else {
                      setHeight(360);
                    }
                  }} />
                </Grid>
              )
            }
          </Grid>
        </Grid>

      </Grid>
    </Box>)
}

export default VisitaView;