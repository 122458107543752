import * as React from "react"
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Profile from "../components/Profile";
import PrivacyPolicy from "../components/PrivacyPolicy";
import MainScreen from "./MainScreen";
import SignOut from "../components/SignOut";
import { Authenticator } from "@aws-amplify/ui-react";
import Landing from "../components/Landing";
import VisitaView from "../components/VisitaView";
import TerminoUso from "../components/TerminoUso";
import VisitaScreen from "./VisitaScreen";

const AuthRequired = (props: React.PropsWithChildren) => {
  return (<Authenticator formFields={{
    signIn: {
      username: {
        label: 'Usuario',
        placeholder: "usuario@empresa.cl"
      },
      password: {
        label: 'Contraseña',
        placeholder: "*********"
      }
    },
    signUp: {
      username: {
        label: 'Usuario',
        placeholder: "usuario@empresa.cl"
      },
      confirm_password: {
        placeholder: "Confirma su contraseña"
      },
    }
  }}>
    {props.children}

  </Authenticator>)
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/app",
    element: <Outlet />,

    children: [
      {
        path: "/app/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/app/termino-uso",
        element: <TerminoUso />,
      },
      
      {
        path: "/app/visita/:vId",
        element: <VisitaView vId="" />,
      },
      {
        path: "/app/private",
        element: <AuthRequired><MainScreen /></AuthRequired>,
        children: [
          {
            path: "/app/private/profile",
            element: <AuthRequired><Profile /></AuthRequired>,
          },
          {
            path: "/app/private/signOut",
            element: <AuthRequired><SignOut /></AuthRequired>,
          },
          {
            path: "/app/private/visits",
            element: <AuthRequired><VisitaScreen /></AuthRequired>,
          },
        ],
      },
    ]
  },
]);

export default router;