import { Badge, Box, Button, Divider, Stack, Typography, Paper, Dialog, DialogTitle, List, ListItem } from "@mui/material";
import Moment from "moment";
import { FWEmpresa } from "../models/customModels";


type EmpresaSelectorProps = {
    empresas: FWEmpresa[]
    onEmpresaSelect: (empresa: FWEmpresa) => void
}

const EmpresaSelector = ({ empresas, onEmpresaSelect }: EmpresaSelectorProps) => {
    console.log("EmpresaSelector", empresas)
    return (
        <Box sx={{ width: '100%' }} alignItems={'center'}>
            <Stack spacing={6} alignItems={"center"} direction={"column"}>
                {
                    empresas.map((x) => {
                        return <EmpresaCard empresa={x} onSelect={onEmpresaSelect} />
                    }
                    )
                }
                {
                    //   <NueveEmpresaCard />
                }
            </Stack>
        </Box>)
}

type EmpresaCardProps = {
    empresa: FWEmpresa,
    onSelect: (empresa: FWEmpresa) => void
}

type EmpresaSelectorDialogProps = {
    open: boolean;
    empresas: FWEmpresa[];
    onEmpresaSelect: (empresa: FWEmpresa) => void
}

export function EmpresaSelectorDialog(props: EmpresaSelectorDialogProps) {
    const { open, empresas, onEmpresaSelect } = props;

    return (
        <Dialog open={open}>
            <DialogTitle>Selecciona tu empresa</DialogTitle>
            <List sx={{ p: 8 }}>
                {
                    empresas.map((x) => {
                        return (
                            <ListItem>
                                <EmpresaCard empresa={x} onSelect={onEmpresaSelect} />
                            </ListItem>)
                    })
                }
            </List>
        </Dialog>
    );
}

const NueveEmpresaCard = () => {
    return (
        <Box alignItems="center">
            <Paper>
                <Button
                    onClick={() => { }}
                >
                    <Box>
                        <Typography mt="3" fontWeight="medium" fontSize="xl">
                            Crear una nueva empresa
                        </Typography>
                    </Box>
                </Button>
            </Paper>
        </Box>
    )
}

const EmpresaCard = ({ empresa, onSelect }: EmpresaCardProps) => {
    return (
        <Box alignItems="center">

            <Button
                onClick={() => onSelect(empresa)}
            >
                <Paper sx={{ width: '20vw' }}>
                    <Box>
                        <Stack alignItems="center" direction={'row'}>
                            <Divider />
                            <Typography fontSize={10} color="coolGray.800">
                                {Moment(empresa.createdAt).format("DD-MM-YYYY")}
                            </Typography>
                        </Stack>
                        <Typography color="coolGray.800" mt="3" fontWeight="medium" fontSize="xl">
                            {empresa.name}
                        </Typography>
                    </Box>
                </Paper>
            </Button>

        </Box>
    );
};


export default EmpresaSelector;