const PrivacyPolicy = () => {
    return (<div>
        <h1>Política de Privacidad de Harvey</h1>

        <p><strong>Fecha de vigencia:</strong> 01-12-2023</p>

        <p>Bienvenido/a a Harvey, una aplicación desarrollada por Daia Systems SPA. La privacidad de nuestros usuarios es de suma importancia para nosotros y nos comprometemos a proteger la información personal que pueda ser recopilada durante el uso de nuestra aplicación. Esta Política de Privacidad explica cómo recopilamos, utilizamos, divulgamos y protegemos su información cuando utiliza nuestra aplicación. Al descargar, instalar y utilizar nuestra aplicación, usted acepta las prácticas descritas en esta política.</p>

        <h2>Información Recopilada</h2>

        <ol>
            <li><strong>Información proporcionada por el Usuario:</strong> No recopilamos información personal identificable a menos que usted la proporcione de manera voluntaria, como al crear una cuenta. Esta información puede incluir su nombre de usuario, dirección de correo electrónico y cualquier otra información que decida compartir con nosotros.</li>

            <li><strong>Datos de Uso:</strong> Recopilamos información sobre cómo utiliza nuestra aplicación, como las funciones que utiliza, el tiempo que pasa en la aplicación y cualquier error o problema que pueda encontrar. Esta información se utiliza de manera anónima y agregada para mejorar la calidad y la experiencia de la aplicación.</li>
        </ol>

        <h2>Uso de la Información</h2>

        <p>La información recopilada se utiliza para los siguientes propósitos:</p>

        <ol>
            <li>Proporcionar y mejorar los servicios de la aplicación.</li>
            <li>Personalizar la experiencia del usuario y proporcionar contenido relevante.</li>
            <li>Enviar comunicaciones relacionadas con la aplicación, como actualizaciones y notificaciones importantes.</li>
            <li>Garantizar el cumplimiento de nuestros Términos de Servicio.</li>
        </ol>

        <h2>Divulgación de la Información</h2>

        <p>No compartimos su información personal con terceros, excepto en las siguientes circunstancias:</p>

        <ol>
            <li><strong>Consentimiento:</strong> Con su consentimiento expreso.</li>
            <li><strong>Requisitos legales:</strong> Cuando sea necesario cumplir con leyes aplicables, reglamentaciones gubernamentales o solicitudes legales.</li>
        </ol>

        <h2>Seguridad de la Información</h2>

        <p>Tomamos medidas razonables para proteger la información recopilada contra accesos no autorizados, divulgación, alteración o destrucción. Sin embargo, ningún método de transmisión por internet o almacenamiento electrónico es completamente seguro, y no podemos garantizar la seguridad absoluta de la información.</p>

        <h2>Eliminación de la Cuenta</h2>

        <p>Usted puede eliminar su cuenta en cualquier momento a través de la configuración de la aplicación. Al hacerlo, se eliminará su información personal de nuestras bases de datos, a menos que estemos legalmente obligados a retenerla.</p>

        <h2>Cambios en la Política de Privacidad</h2>

        <p>Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Cualquier cambio significativo se notificará a través de la aplicación o por otros medios apropiados. Le recomendamos revisar periódicamente esta política para estar informado sobre cómo protegemos su información.</p>

        <p>Si tiene alguna pregunta o inquietud sobre nuestra Política de Privacidad, no dude en ponerse en contacto con nosotros a través de soporte@daia.cl.</p>

        <p>Gracias por confiar en Harvey.</p>

        <p>Atentamente,<br />
            Daia Systems SPA<br />
            </p>
    </div>);
}

export default PrivacyPolicy;