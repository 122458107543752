const TerminoUso = () => {
    return (<div style={{ margin: 'auto', maxWidth: '800px' }}>
        <h1>Política de Uso y Términos de "Harvey"</h1>
        <h1>Fecha de entrada en vigencia: 01-01-2024</h1>

        <p>Bienvenido a "Harvey", la aplicación diseñada para facilitar el registro de visitas en terreno mediante la toma de notas, fotos y la captura de ubicación. Antes de utilizar nuestra aplicación, le solicitamos que lea detenidamente y comprenda los siguientes términos y condiciones que rigen su uso. Al utilizar "Harvey", usted acepta cumplir con estas políticas.</p>

        <h2>1. Uso Adecuado:</h2>
        <ul>
            <li>La aplicación "Harvey" está destinada únicamente para el registro de visitas en entornos profesionales y comerciales.</li>
            <li>El usuario se compromete a utilizar la aplicación de manera ética y legal, respetando las leyes y regulaciones locales e internacionales.</li>
        </ul>

        <h2>2. Pérdida de Datos:</h2>
        <ul>
            <li>Siendo un servicio entregado de forma gratis, la empresa no se hace responsable por la pérdida accidental de datos registrados en la aplicación.</li>
            <li>Se recomienda realizar copias de seguridad periódicas de la información almacenada en "Harvey" para prevenir posibles pérdidas de datos. (bajar visitas a PDF)</li>
        </ul>

        <h2>3. Eliminación de Archivos e Información:</h2>
        <ul>
            <li>La empresa se reserva el derecho de eliminar archivos o información que considere indebida, contraria a los valores de la empresa o que viole las políticas establecidas en este documento.</li>
            <li>La eliminación de archivos o información puede ocurrir sin previo aviso y no generará responsabilidad para la empresa.</li>
        </ul>

        <h2>4. Privacidad y Seguridad:</h2>
        <ul>
            <li>La información personal y los datos recopilados por "Harvey" se manejarán de acuerdo con nuestra <a href="/app/privacy-policy">Política de Privacidad</a>.</li>
            <li>Se implementarán medidas de seguridad para proteger la integridad y confidencialidad de los datos almacenados en la aplicación.</li>
        </ul>

        <h2>5. Actualizaciones y Cambios:</h2>
        <ul>
            <li>La empresa se reserva el derecho de realizar actualizaciones, cambios o mejoras en la aplicación "Harvey" sin previo aviso.</li>
            <li>La continuidad del uso de la aplicación después de una actualización implica la aceptación de los nuevos términos y condiciones.</li>
        </ul>

        <h2>6. Soporte Técnico:</h2>
        <ul>
            <li>El soporte técnico se proporcionará según la disponibilidad de recursos de la empresa.</li>
            <li>Los usuarios pueden ponerse en contacto con el soporte técnico a través de <a href="mailto:soporte@daia.cl">correo electrónico</a> para resolver problemas o realizar consultas.</li>
        </ul>

        <h2>7. Terminación del Uso:</h2>
        <ul>
            <li>La empresa se reserva el derecho de suspender o terminar el acceso a "Harvey" en caso de violación de estos términos y condiciones.</li>
            <li>El usuario puede dejar de utilizar la aplicación en cualquier momento, respetando las políticas de eliminación de datos mencionadas anteriormente.</li>
        </ul>
        <br></br>
        <p>Al utilizar la aplicación "Harvey", usted reconoce y acepta estos términos y condiciones. La empresa se reserva el derecho de modificar este documento en cualquier momento, y dichas modificaciones entrarán en vigencia al ser publicadas en la aplicación.</p>

        <p>Gracias por utilizar "Harvey". Si tiene alguna pregunta o inquietud, comuníquese con nuestro equipo de soporte técnico.</p>

    </div>);
}

export default TerminoUso;