import * as React from "react"
import { Outlet } from "react-router-dom";

const Landing = () => {

    return (
        <div>
            {window.location.href = 'landing/index.html'}
        </div>
    )
}

export default Landing;