/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
export type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getEmpresa = /* GraphQL */ `query GetEmpresa($id: ID!) {
  getEmpresa(id: $id) {
    id
    name
    campos {
      nextToken
      startedAt
      __typename
    }
    usuarios {
      nextToken
      startedAt
      __typename
    }
    plan
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmpresaQueryVariables,
  APITypes.GetEmpresaQuery
>;
export const listEmpresas = /* GraphQL */ `query ListEmpresas(
  $filter: ModelEmpresaFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmpresas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      plan
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmpresasQueryVariables,
  APITypes.ListEmpresasQuery
>;
export const syncEmpresas = /* GraphQL */ `query SyncEmpresas(
  $filter: ModelEmpresaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEmpresas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      plan
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEmpresasQueryVariables,
  APITypes.SyncEmpresasQuery
>;
export const getUsuarioAcceso = /* GraphQL */ `query GetUsuarioAcceso($id: ID!) {
  getUsuarioAcceso(id: $id) {
    username
    empresa {
      id
      name
      plan
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nivel
    tour
    parameters
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    empresaUsuariosId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUsuarioAccesoQueryVariables,
  APITypes.GetUsuarioAccesoQuery
>;
export const listUsuarioAccesos = /* GraphQL */ `query ListUsuarioAccesos(
  $filter: ModelUsuarioAccesoFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsuarioAccesos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      username
      nivel
      tour
      parameters
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      empresaUsuariosId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUsuarioAccesosQueryVariables,
  APITypes.ListUsuarioAccesosQuery
>;
export const syncUsuarioAccesos = /* GraphQL */ `query SyncUsuarioAccesos(
  $filter: ModelUsuarioAccesoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsuarioAccesos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      username
      nivel
      tour
      parameters
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      empresaUsuariosId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUsuarioAccesosQueryVariables,
  APITypes.SyncUsuarioAccesosQuery
>;
export const getCampo = /* GraphQL */ `query GetCampo($id: ID!) {
  getCampo(id: $id) {
    id
    name
    empresa {
      id
      name
      plan
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    visitas {
      nextToken
      startedAt
      __typename
    }
    cuarteles {
      name
      __typename
    }
    coords {
      lat
      lon
      __typename
    }
    location
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    empresaCamposId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCampoQueryVariables, APITypes.GetCampoQuery>;
export const listCampos = /* GraphQL */ `query ListCampos(
  $filter: ModelCampoFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      empresaCamposId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCamposQueryVariables,
  APITypes.ListCamposQuery
>;
export const syncCampos = /* GraphQL */ `query SyncCampos(
  $filter: ModelCampoFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCampos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      empresaCamposId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCamposQueryVariables,
  APITypes.SyncCamposQuery
>;
export const getVisita = /* GraphQL */ `query GetVisita($id: ID!) {
  getVisita(id: $id) {
    id
    campo {
      id
      name
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      empresaCamposId
      __typename
    }
    cuartel {
      name
      __typename
    }
    fecha
    content {
      pictures
      body
      __typename
    }
    operator
    variedad {
      name
      code
      __typename
    }
    tags
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    campoVisitasId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetVisitaQueryVariables, APITypes.GetVisitaQuery>;
export const listVisitas = /* GraphQL */ `query ListVisitas(
  $filter: ModelVisitaFilterInput
  $limit: Int
  $nextToken: String
) {
  listVisitas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      fecha
      operator
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      campoVisitasId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisitasQueryVariables,
  APITypes.ListVisitasQuery
>;
export const syncVisitas = /* GraphQL */ `query SyncVisitas(
  $filter: ModelVisitaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncVisitas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fecha
      operator
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      campoVisitasId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncVisitasQueryVariables,
  APITypes.SyncVisitasQuery
>;
