import React, { useContext, useEffect, useState } from "react"
import { Box, Button, Grid, Paper, Stack } from "@mui/material"
import { LazyVisita, Visita } from "../models";
import { UserContext } from "./MainScreen";
import { generateClient } from "aws-amplify/api";
import { listVisitasWithEmpresa } from '../graphql/customQueries';
import { listVisitas, listCampos } from "../graphql/queries";
import { buildIdInArrayFilter } from "../ultis";
import VisitaCard from "../components/VisitaCard";
import VisitaView from "../components/VisitaView";

const client = generateClient();

const VisitaScreen = () => {
    const userContext = useContext(UserContext);
    const [visitas, setVisitas] = useState<LazyVisita[]>([]);
    const [selectedVisita, setSelectedVisita] = useState<LazyVisita | null>(null);

    const fetchVisitas = async () => {
        if (userContext.campos.length > 0 && userContext.empresa.id.length > 0) {
            console.log("Buscando visitas...", userContext.empresa.id);
            const multipleIdFilter = buildIdInArrayFilter("campoVisitasId", userContext.campos.map(c => c.id));
            const myVisitas = await client.graphql({
                query: listVisitas,
                variables: {
                    filter:
                        multipleIdFilter,
                        limit: 10
                },
            });

            //@ts-ignore
            setVisitas(myVisitas.data.listVisitas.items);
        }
        else {
            //No hay campos, no hay visitas
            setVisitas([])
        }
    }

    useEffect(() => {
        //Cargamos las 10 ultimas visitas
        fetchVisitas()
    }, [userContext.empresa.id])


    return <Box>
        <Grid container>
            <Grid item xs={12} sm={3} display={{ xs: selectedVisita ? "none" : "block", sm: "block" }}>
                { /* Lista */}
                <Stack maxHeight={"90vh"} overflow={"auto"}>
                    {
                        visitas.map((v) => {
                            return (<Button onClick={() => {
                                setSelectedVisita(v)
                            }
                            }>
                                <VisitaCard visita={v} dateFormat={"DD-MM-YYYY HH:mm"} />
                            </Button>)

                        })
                    }
                </Stack>
            </Grid>

            <Grid item xs={12} sm={0} display={{ xs: selectedVisita ? "block" : "none", sm: "none", md: "none", lg: "none", xl: "none" }}>
                <Paper>
                    { /* Detalle */}
                    {
                        selectedVisita ?
                            <>
                                <VisitaView vId={selectedVisita.id} />
                                <Button variant="contained" onClick={() => setSelectedVisita(null)}>Cerrar</Button>
                            </>
                            : <></>
                    }

                </Paper>
            </Grid>

            <Grid item xs={0} md={9} display={{ xs: "none", md: "block" }}>
                <Paper sx={{ maxHeight: "100vh" }}>
                    { /* Detalle */}
                    {
                        selectedVisita ?
                            <VisitaView vId={selectedVisita.id} /> : <></>
                    }
                </Paper>
            </Grid>
        </Grid>
    </Box>
}

export default VisitaScreen;