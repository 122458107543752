export const buildIdInArrayFilter = (keyName: string, arrayCondition: Array<string>) => {
    //Juntemos varias condiciones en una cadena de OR que puede acceptar GraphQL
    let orFilterString = "{ \"or\": [ $conditions ] }";
    let orFilter = {};
    let conditions = ""

    for (let k in arrayCondition) {
        const arrayValue = arrayCondition[k];
        const condition = "{ \"" + keyName + "\": { \"eq\": \"" + arrayValue + "\" } },";
        conditions = conditions + condition
    }

    conditions = conditions.substring(0, conditions.length - 1);
    orFilterString = orFilterString.replace("$conditions", conditions)
    orFilter = JSON.parse(orFilterString);
    return orFilter
}