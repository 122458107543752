import React, { useEffect, useMemo, useState } from "react"
import { createContext } from 'react';
import { Box, IconButton, Toolbar, Typography, CssBaseline, Drawer, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Outlet, useNavigate } from "react-router-dom"
import { useAuthenticator } from "@aws-amplify/ui-react"
import MenuIcon from '@mui/icons-material/Menu';
import { ArrowLeftOutlined } from "@mui/icons-material";
import { AuthUser } from "aws-amplify/auth";
import { fetchUserAttributes } from 'aws-amplify/auth';
import { generateClient } from "aws-amplify/api";
import { getEmpresa, listCampos, listUsuarioAccesos } from '../graphql/queries';
import { EmpresaSelectorDialog } from "../components/EmpresaSelector";
import { FWCampos, FWEmpresa } from "../models/customModels";

const mockedUser: AuthUser = {
    userId: '',
    username: '',
    signInDetails: { loginId: '' }
}

const mockedCampos: FWCampos[] = [{ id: '', name: '', location: '' }]

export const UserContext = createContext({ user: mockedUser, empresa: { id: '' }, campos: mockedCampos });
const client = generateClient();

const baseUrl = "/app/private";

const menu = [
    { name: 'Inicio', link: baseUrl },
    { name: 'Visitas', link: baseUrl + '/visits' },
    { name: 'Perfil', link: baseUrl + '/profile' },
    { name: 'Privacidad', link: '/app/privacy-policy' },
]

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const MainScreen = () => {
    const { user } = useAuthenticator();
    const [userEmail, setUserEmail] = useState<string>("");
    const [linkedEmpresas, setLinkedEmpresas] = useState<FWEmpresa[]>([]);
    const [selectedEmpresa, setEmpresa] = useState<FWEmpresa>({ id: "" });
    const [selectedCampos, setCampos] = useState<FWCampos[]>([])
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
        fetchUserEmail();
        fetchCompanyId();
    }, [user.username, userEmail]);

    const fetchUserEmail = async () => {
        setUserEmail((await fetchUserAttributes()).email || "");
    }

    const fetchCompanyId = async () => {
        //Recuperamos las empresas a los cuales tenemos acceso
        if (user.userId && userEmail.length > 0) {
            //Recuperamos sus accesos
            const userAccess = await client.graphql({
                query: listUsuarioAccesos,
                variables: {
                    filter: {
                        username: { eq: userEmail }
                    }
                },
            });

            //Recuperamos las empresas vinculadas a sus accesos
            const empresas = userAccess.data.listUsuarioAccesos.items.map(async (ua) => {
                if (ua.empresaUsuariosId) {
                    const empresa = await client.graphql({
                        query: getEmpresa,
                        variables: {
                            id: ua.empresaUsuariosId
                        },
                    });
                    const linkedEmpresa = empresa.data.getEmpresa;
                    return { id: linkedEmpresa?.id || "", name: linkedEmpresa?.name, createdat: linkedEmpresa?.createdAt };
                }
                return { id: '', name: '', createdat: '' }
            });

            //Esperamos resolver cada empresa, y las mostramos
            Promise.all(empresas).then((x) => setLinkedEmpresas(x));
        }
    }

    const onEmpresaSelected = async (empresa: FWEmpresa) => {
        console.log("Buscando campos paara ", empresa.name);
        setEmpresa(empresa)
        const myCampos = await client.graphql({
            query: listCampos,
            variables: {
                filter: {
                    //@ts-ignore
                    empresaCamposId: { eq: empresa.id }
                }
            },
        });
        console.log("Busqueda terminada");
        setCampos(myCampos.data.listCampos.items);
    }

    return (
        <UserContext.Provider value={{ user: user, empresa: selectedEmpresa, campos: selectedCampos }}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Harvey - {selectedEmpresa.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <ArrowLeftOutlined />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {menu.map((menuItem, index) => (
                            <ListItem key={index} disablePadding onClick={() => navigate(menuItem.link)}>
                                <ListItemButton>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <ListItem disablePadding onClick={() => setEmpresa({ id: '' })}>
                            <ListItemButton>
                                <ListItemIcon>

                                </ListItemIcon>
                                <ListItemText primary={"Cambiar empresa"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding onClick={() => navigate(baseUrl + "/signOut")}>
                            <ListItemButton>
                                <ListItemIcon>

                                </ListItemIcon>
                                <ListItemText primary={"Salir"} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Drawer>
                <Main open={open}>
                    <DrawerHeader />
                    {
                        //Mostramos el selector de empresa en caso de no tener nada selcionado
                    }
                    <EmpresaSelectorDialog open={selectedEmpresa.id == ""} empresas={linkedEmpresas} onEmpresaSelect={(empresa) => {
                        onEmpresaSelected(empresa);
                    }} />
                    <Outlet />
                </Main>
            </Box>
        </UserContext.Provider>)
}

export default MainScreen;