import React from 'react';
import {
    useAuthenticator,
} from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

const SignOut = () => {
    const { user, signOut } = useAuthenticator()
    const navigate = useNavigate();

    setTimeout(() => {
        console.log("SignOut");
        signOut()
        navigate("/")

    }, 500);
    return (
        <div>
            Lo estamos desconectando...
        </div>)
}

export default SignOut;